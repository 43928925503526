body {
  /* background: -webkit-linear-gradient(left, #3931af, #00c6ff); */
  /* background-color: #00c6ff; */
  transition: all 0.3s;
  height: 100vh;
  background-color: #253575;
  background-image: linear-gradient(to bottom right, #253575, #ef9007);
}

html {
  overflow: auto;
}
body {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
}
#note-success {
  display: none;
}
.note-email {
  margin-bottom: 30px !important;
  margin-top: 20px !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  width: 100%;
}
.captcha-holder {
  margin: auto;
  display: flex;
  justify-content: center;
  width: 100%;
}
.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
.register {
  /* background-color: #fff; */

  margin-top: 3%;
  padding: 3%;
}
.register-left {
  text-align: center;
  color: #fff;
  margin-top: 4%;
}
.btn-up-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-size {
  font-size: small;
  margin: 10px 0;
  color: rgb(255, 41, 41);
  font-weight: bold;
}
.volunteer-register-left {
  text-align: center;
  color: #fff;
  margin-top: 4%;
}
.btn-register {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.register-left input {
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  width: 60%;
  background: #f8f9fa;
  font-weight: bold;
  color: #383d41;
  margin-top: 30%;
  margin-bottom: 3%;
  cursor: pointer;
}

.register-right {
  background: #dddedf;
  border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.caution {
  margin-top: 0;
  color: #212529;
  text-align: center;
}
.register-left-logo {
  width: 40%;
  margin: auto;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 1s infinite alternate;
}
.volunteer-register-left img {
  width: 100%;
}
.register-left-logo h4 {
  font-weight: 900;

  font-size: 25px;
  font-family: "Lobster", cursive;
  letter-spacing: 1.4px;
}
.register-left img {
  margin-top: 15%;
  margin-bottom: 5%;
  width: 40%;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
.register-left p {
  font-weight: lighter;
  padding: 12%;
  margin-top: -9%;
}
.register .register-form {
  padding: 10%;
  margin-top: 10%;
  padding-bottom: 20px;
}
.btnRegister {
  float: right;
  margin-top: 10%;
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  background: #0062cc;
  color: #fff;
  font-weight: 600;
  width: 50%;
  cursor: pointer;
}
.register .nav-tabs {
  margin-top: 3%;
  border: none;
  background: #0062cc;
  border-radius: 1.5rem;
  width: 40%;
  float: right;
}
.register .nav-tabs .nav-link {
  padding: 2%;
  height: 34px;
  font-weight: 600;
  color: #fff;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.register .nav-tabs .nav-link:hover {
  border: none;
}
.register .nav-tabs .nav-link.active {
  width: 100px;
  color: #0062cc;
  border: 2px solid #0062cc;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}
.register-heading {
  text-align: center;
  margin-top: 8%;
  margin-bottom: -15%;
  color: #3660a9;
  font-weight: 900;

  font-size: 40px;
  font-family: "Lobster", cursive;
  letter-spacing: 1.4px;
}

@media only screen and (max-width: 600px) {
  .register-right {
    border-radius: 0;
  }
  .captcha-holder {
    margin-top: 20px;
  }
}

/* LOading Spinner */

.loading-spinner {
  width: 70px;
  height: 70px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid rgb(37 99 235); /* Blue-600 */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  /* height: 350px; */
  margin-bottom: 30px;
}
.register-left img {
  width: 130px !important;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 100vw;
  margin: 20px 0px;
}
.glass-btn {
  width: 40vw;
  height: 120px;
  min-width: 310px;

  /* background-color: #fafafa; */
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  margin-left: 1em;
  margin-right: 1em;
  cursor: pointer;
  transition: all 0.3s;
}
.closed-text {
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: large;
  /* font-weight: bold; */
}
.image {
  width: 90%;
  height: 70px;
}

.image2 {
  width: 90%;
  height: 90px;
}

.glass-btn.blue-btn {
  -webkit-box-shadow: 0px 20px 20px -17px rgba(0, 111, 255, 0.53);
  -moz-box-shadow: 0px 20px 20px -17px rgba(0, 111, 255, 0.53);
  box-shadow: 0px 20px 20px -17px rgba(0, 111, 255, 0.53);
}
.glass-btn.blue-btn:hover {
  -webkit-box-shadow: 0px 20px 35px -16px rgba(0, 111, 255, 0.65);
  -moz-box-shadow: 0px 20px 35px -16px rgba(0, 111, 255, 0.65);
  box-shadow: 0px 20px 35px -16px rgba(0, 111, 255, 0.65);
}
.glass-btn.blue-btn .content {
  font-size: 4em;
  font-family: "Roboto", sans-serif;
  background: linear-gradient(to right, #006fff, #00acff 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 10px 37px rgba(0, 111, 255, 0.8);
}
.glass-btn.red-btn {
  -webkit-box-shadow: 0px 20px 20px -17px rgba(255, 16, 39, 0.5);
  -moz-box-shadow: 0px 20px 20px -17px rgba(255, 16, 39, 0.5);
  box-shadow: 0px 20px 20px -17px rgba(255, 16, 39, 0.5);
}
.glass-btn.red-btn:hover {
  -webkit-box-shadow: 0px 20px 35px -16px rgba(255, 16, 39, 0.57);
  -moz-box-shadow: 0px 20px 35px -16px rgba(255, 16, 39, 0.57);
  box-shadow: 0px 20px 35px -16px rgba(255, 16, 39, 0.57);
}
.glass-btn.red-btn .content {
  font-size: 4em;
  font-family: "Roboto", sans-serif;
  background: linear-gradient(to right, #ff1027, #ff4f06 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 10px 37px rgba(255, 16, 39, 0.6);
}
.glass-btn.amber-btn {
  -webkit-box-shadow: 0px 20px 20px -17px rgba(255, 159, 16, 0.53);
  -moz-box-shadow: 0px 20px 20px -17px rgba(255, 159, 16, 0.53);
  box-shadow: 0px 20px 20px -17px rgba(255, 159, 16, 0.53);
}
.glass-btn.amber-btn:hover {
  -webkit-box-shadow: 0px 20px 35px -16px rgba(255, 159, 16, 0.6);
  -moz-box-shadow: 0px 20px 35px -16px rgba(255, 159, 16, 0.6);
  box-shadow: 0px 20px 35px -16px rgba(255, 159, 16, 0.6);
}
.glass-btn.amber-btn .content {
  font-size: 4em;
  font-family: "Roboto", sans-serif;
  background: linear-gradient(to right, #ff8d00, #f7ad07 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 10px 37px rgba(255, 94, 16, 0.7);
}
.glass-btn:hover {
  /* background-color: #f5f5f5; */
  background-color: grey;

  transform: translateY(-5px);
  transition: all 0.3s;
}
