#register-type label{
    margin: 0 10px;
    cursor: pointer;
}

.photo-preview{
    width: 150px;
    aspect-ratio: 3/4;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    margin: auto;
    /* border: 2px solid rgb(166, 166, 166); */
    box-shadow: 0px 4px 8px rgb(125, 125, 125);
}

.photo-wrapper{
    display: flex;
    justify-content: center;
}